<template>
  <div class="flix-list-group">
    <a class="flix-list-group-item flix-html-a" @click.prevent="setData(assistent.ID)" :class="{'flix-active': checkData(assistent.ID)}" :key="assistent.ID" href="#" v-for="assistent in $store.getters.assistents">
      <flixIcon v-if="checkData(assistent.ID)" id="check" />
      <flixIcon v-else id="unchecked" />
        {{ assistent.title }}
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: [Object, Array],
    callback: Function
  },
  mounted () {

  },
  computed: {

  },
  data () {
    return {
      newData: JSON.parse(JSON.stringify(this.data.assistents))
    }
  },
  methods: {
    swap (json) {
      var ret = {}
      for (var key in json) {
        ret[json[key]] = key
      }
      return ret
    },
    setData (id) {
      var nd = this.swap(this.newData)
      if (typeof nd[id] !== 'undefined') {
        delete nd[id]
        this.newData = Object.keys(nd)
      } else {
        this.newData.push(id)
      }
      this.callback(this.newData)
      this.$store.dispatch('setTargetPage', {
        user: this.$getUserVariables().user.md5_id,
        ID: this.data.ID,
        data: this.data.layout,
        meta: { assistents: this.newData, method: this.data.method },
        callback: function () { }
      })
    },
    checkData (id) {
      if (Object.values(this.newData).indexOf(id) !== -1) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
